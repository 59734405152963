export const environment = {
  production: true,
    currency: '',

    // PARAMETROS ALANIANS
    firebase: {
        apiKey: "AIzaSyD1Qko8XLxFP8zuufMeXe3KCSocw-mVAKo",
        authDomain: "alanians-pro.firebaseapp.com",
        projectId: "alanians-pro",
        storageBucket: "alanians-pro.appspot.com",
        messagingSenderId: "273707274282",
        appId: "1:273707274282:web:ee75d24b6110fe43d8f353",
        measurementId: "G-Z57M10R51B"
    },
    urlBase: 'https://us-central1-alanians-pro.cloudfunctions.net',
    urlBaseWompy: 'https://us-central1-alanians-pro.cloudfunctions.net/Wompi',
    apikey: '500126a8efb-760bf55db7bff-404e-644437de',
    googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',

    // CREDENCIALES DE PRO ITWC
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    // TWILIO CREDENCIALES IanOS APP
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/VerificationCheck`,
        TWILIO_AUTH_TOKEN: '97cd517dd6104fe0d1a9e6595510487f',
        TWILIO_ACCOUNT_SID: 'AC3cd8a9a45ccbb432105181d79f45b95d',
        TWILIO_SERVICE_TOKEN: 'VA5681a04e24d8bc289d6008f39af37744'
    },
};
